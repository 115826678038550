import React from 'react'
import Home from '../components/Home'
import About from '../components/About'
import Gallery from '../components/Gallery'
import Testimonial from '../components/Testimonial'
import OurWork from '../components/OurWork'

const MainPage = () => {
  return (
    <div>
        <Home />
        <About />
        <Gallery />
        <Testimonial />
        <OurWork /> 
    </div>
  )
}

export default MainPage