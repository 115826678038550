import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";

const OurWork = () => {
  const settings = {
    dots: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "50px",
    slidesToShow: 3,
    speed: 100,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <div className="ourWork" id="testimonials">
      <div className="heading">
        <img src={icon1} alt="icon" />
        <p>What We Do</p>
        <img src={icon2} alt="icon" />
      </div>

      <Slider {...settings}>
        <div className="card">
          <div className="image">
            <img src="https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215659/test_Api/mpbyoc0h97njq6ga2sjp.jpg" alt="Shelter" />
          </div>
          <div>
            <p className="sub-text">Shelter</p>
            <p className="inner-text">
              We have a very big adequate facility to provide good shelters to these rescued gauvansh. We continue building cow sheds as the number of cows being protected increases day by day.
            </p>
          </div>
        </div>

        <div className="card">
          <div className="image">
            <img src="https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215716/test_Api/sdnlwyzuzll5zqye07iy.jpg" alt="Treatment" />
          </div>
          <div>
            <p className="sub-text">Treatment</p>
            <p className="inner-text">
              We have Doctors available 24/7,providing in house medical facilities. We maintain the supply of necessary medicines and vaccinations to address various health conditions.
            </p>
          </div>
        </div>

        <div className="card">
          <div className="image">
            <img src="https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215845/test_Api/nh2b4c8lb8wczoqjcn6g.jpg" alt="Free Milk Distribution" />
          </div>
          <div>
            <p className="sub-text">Free Milk Distribution</p>
            <p className="inner-text">
            The limited quantity of milk produced in our Gaushala is generously distributed at no cost to  local ashrams, and charitable hospitals. We are committed to supporting our community.
            </p>
          </div>
        </div>

        <div className="card">
          <div className="image">
            <img src="https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215923/test_Api/y0uoguh9bouhbouhhtfb.jpg" alt="Protection Drives" />
          </div>
          <div>
            <p className="sub-text">Protection Drives</p>
            <p className="inner-text">
            We actively conduct rescue drives to bring injured and stray cows and bulls to our Gaushala, where we dedicate ourselves to their care and well-being. Once in our care, these animals receive the best  facilities.</p>
          </div>
        </div>

        <div className="card">
          <div className="image">
            <img src="https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215984/test_Api/wcnjdydmak8gduc7eb90.jpg" alt="Protection to Cows" />
          </div>
          <div>
            <p className="sub-text">Protection to Cows</p>
            <p className="inner-text">
              Our team ensures the well being by providing shelter, food and round the clock care.Morever we have fully equipped medical facility site to address any health concern.
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default OurWork;
