import React from "react";

const Disclaimer = () => {
  return (
    <div className="disclaimer" id="disclaimer">
      <div className="heading">
        <p>Disclaimer</p>
      </div>

      <div className="content">
        <p>
          ShriVrihad Narayan Gaushala is a dedicated sanctuary committed to the
          welfare and protection of cows. Our mission is to provide safe
          shelter, essential medical treatment, and care for rescued cows,
          ensuring their well-being and dignity. We operate on the principles of
          compassion and respect for all living beings, relying on the generous
          donations and support of our community to sustain our efforts. Join us
          in our endeavor to protect and nurture these gentle creatures by
          contributing to our cause and helping us create a better world for
          them
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;
