import React, { useState } from 'react';
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";


const Gallery = () => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [activeImage, setActiveImage] = useState(null);

  const images = [
    { src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724217258/test_Api/oakrdpp5r6iacptcqtcb.jpg" },
    { src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724217424/test_Api/ogharpxzznobz4ukb9gh.jpg" },
    { src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724244521/test_Api/fvm7qbewtpplyv01lzbe.jpg" },
    { src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724217634/test_Api/m956xu1jyussbkn0k3q3.jpg" },
    { src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724217701/test_Api/j3uive0x9nygejoaj3vq.jpg" },
    { src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724217792/test_Api/l6q6hvsf5qeevwj9kag6.jpg" },
    { src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724244634/test_Api/pa5wcm1wellobvnipw3y.webp" },
    { src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724217941/test_Api/vxnwf6mwuqvthfyz0cgh.jpg" },
    { src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724217997/test_Api/coocngwq5yshkommxmo9.jpg" },
    { src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724218080/test_Api/kk7svc4nkgp7nocitvxt.jpg" },
    { src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724218145/test_Api/kfylzyhhoiri0br09byg.jpg" },
    { src: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724244692/test_Api/ijsupb8kdk7nm7mbqbtb.jpg" },
  ];

  const handleImageClick = (src) => {
    setActiveImage(src);
    setIsZoomed(true);
  };

  const handleCloseZoom = () => {
    setIsZoomed(false);
    setActiveImage(null);
  };

  return (
    <>
      <div id='gallery' className={`gallery ${isZoomed ? 'blurred' : ''}`}>
        <div className='heading'>
          <img src={icon1} alt="gaushala" />
          <p>Our Gallery</p>
          <img src={icon2} alt="gaushala" />
        </div>
        <div className='gallery-grid'>
          {images.map((image, index) => (
            <div className='card' key={index}>
              <div className='card-image'>
                <img 
                  src={image.src} 
                  alt="gaushala" 
                  onClick={() => handleImageClick(image.src)} 
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {isZoomed && (
        <div className="zoomed-image-overlay" onClick={handleCloseZoom}>
          <img src={activeImage} alt="Zoomed" className="zoomed-image" />
        </div>
      )}
    </>
  );
};

export default Gallery;