import React from "react";
import myImage from "../assests/calf.JPG";
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";


const About = () => {
  return (
    <div id="about-section" className="about">
      <div className="about-image">
        <img src={myImage} alt="About us" />
      </div>
      <div className="heading">
        <div className="heading-aboutus">
          <img src={icon1} alt="gaushala" />
          <p className="about-header">About Us</p>
          <img src={icon2} alt="gaushala" />
        </div>
        <div className="right-side-down">
          <p>
            Shrinarayan Vrihad Gaushala, owned by Vallabhi Green Foundation, began
            its journey in 2023 in Jaunpur with just 20 cows. Today, we are proud
            to shelter and care for over 400 cows and bulls. Our mission is to
            protect, feed, and provide shelter to ailing, abandoned, and stray
            indigenous cows and bulls. Many of these animals have been rescued
            from difficult circumstances, and they receive round-the-clock care
            from our dedicated team of Gausewaks, including proper medical
            attention. Our Gaushala operates under the guidance of compassionate
            leaders, ensuring that these cows are treated with utmost respect and
            care. We do not exploit them for milk production but distribute it
            freely to saints, ascetics, volunteers, and ashrams in need. As our
            efforts continue, the number of cows and bulls entrusted to our care
            grows steadily, reflecting our commitment to their welfare and protection.
            <br />
          </p>
            <div className="readmore_btn">
              <Link to="/more">Read More</Link>
              <FaArrowRightLong className="arrow-button" />
            </div>
        </div>
      </div>
    </div>
  );
};

export default About;
