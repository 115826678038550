import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { Link } from "react-router-dom";
import Qr from "../assests/QR.jpg"

const Contact = () => {
  return (
    <div id="contact" className="contact">
      <div className="address">
        <p>
          <FaLocationDot color="white" size={30} className="icons" />
          Village: Gairwah, Post: Arshiya, Block: Suithakala, Tehsil:Shahganj,
          Dist: Jaunpur, Uttar Pradesh
        </p>
        <p>
          <FaLocationDot color="white" size={30} className="icons" />
          Village: Mainuddinpur, Post: Mitawan, Block: Tezi Bazar, Tehsil:Badlapur,
          Dist: Jaunpur, Uttar Pradesh
        </p>
        <p>
          <FaWhatsapp color="green" size={30} /> +91 8595952508
        </p>
        <p>
          <CiMail color="red" size={30} />
          vgf@vallabhiindia.com
        </p>
      </div>

      <div className="termsandcondition">
        <p>
          <Link to="/privacy">Privacy Policy</Link>
        </p>
        <p>
          <Link to="/disclaimer">Disclaimer</Link>
        </p>
        <p>
          <Link to="/refund">Refund Policy</Link>
        </p>
        <p>
          <Link to="/terms">Terms and Condition</Link>
        </p>
      </div>

      <div className="QR">
        <img src={Qr}  alt="gaushala"/>
        <p>Donate Here</p>
      </div>
    </div>
  );
};

export default Contact;
