import React from "react";

const PrivacyPage = () => {
  return (
    <div className="refund" id="refund">
      <div className="heading">
        <p>REFUND POLICY</p>
      </div>

      <div className="content">
        <p>
          At ShriVrihad Narayan Gaushala, all donations are final and
          non-refundable. We deeply appreciate your contributions and support
          towards our mission. In the event that a payment gets stuck or you
          encounter any issues during the donation process, please contact our
          helpline at [helpline number] or email us at [email address]. Our team
          will promptly investigate and resolve any payment-related concerns to
          ensure a smooth and satisfactory experience for our donors. Thank you
          for your understanding and support.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPage;
