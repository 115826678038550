import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import icon1 from "../assests/icon1.png";
import icon2 from "../assests/icon2.png"; 
import { Navigation, Pagination } from 'swiper/modules';

const testimonials = [
  {
    image: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215105/test_Api/ol4qgmg7woogas2b2czw.png",
    text: "I recently had the pleasure of visiting Shrinarayan Vrihad Gaushala, and it was a truly heartwarming experience. The dedication and care provided to the cows here are exemplary. The gaushala is well-maintained, ensuring a clean and comfortable environment for the animals.",
    name: "Shivam",
    title: "Data Scientist"
  },
  {
    image: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215175/test_Api/jvuuhyyehqldvjgilulz.png",
    text: "Visiting Shrinarayan Vrihad Gaushala was not only an educational experience but also a deeply fulfilling one. I highly recommend it to anyone interested in learning more about the importance of cow welfare and sustainable farming practices. Kudos to the entire team for their hard work and dedication!",
    name: "Rahul Kumar",
    title: "Product Manager (Paytm)"
  },
  {
    image: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215383/test_Api/trwton1bwdp9ac421wtd.png",
    text: "I recently had the pleasure of visiting Shrinarayan Vrihad Gaushala, and it was a truly heartwarming and enriching experience. The dedication and care provided to the cows here are exemplary. The gaushala is impeccably maintained, ensuring a clean and comfortable environment.",
    name: "Rohan Srivastava",
    title: "CA"
  },
  {
    image: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215313/test_Api/hcdnao8mm3mrgmpmqcxp.jpg",
    text: "One of the standout features of Shrinarayan Vrihad Gaushala is its focus on sustainable and eco-friendly practices. The gaushala utilizes organic feed, employs green energy solutions, and implements innovative waste management techniques.",
    name: "Geet Shukla",
    title: "CXO (Vallabhi Groups)"
  },
  {
    image: "https://res.cloudinary.com/dcb6tgaps/image/upload/v1724215463/test_Api/z4monsbpi3eijirvuzxu.jpg",
    text: "Visiting Shrinarayan Vrihad Gaushala was not only an educational experience but also a deeply fulfilling one. It is a place where compassion, sustainability, and tradition come together to create a sanctuary for cows.",
    name: "Shashwat Mishra",
    title: "Gausevak"
  }
];

const OurWork = () => {
  const [readMore, setReadMore] = useState(Array(testimonials.length).fill(false));

  const handleReadMoreToggle = (index) => {
    const newReadMore = [...readMore];
    newReadMore[index] = !newReadMore[index];
    setReadMore(newReadMore);
  };

  return (
    <div className='testimonial' id='ourWork'>
      <div className='heading'>
        <img src={icon1} alt="icon1" />
        <p>Testimonials</p>
        <img src={icon2} alt="icon2" />
      </div>

      <section className="testimonial-section">
        <div className="swiper mySwiper">
          <Swiper
            className='swiper-wrapper'
            loop={true}
            navigation={true} 
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide className='swiper-slide' key={index}>
                <div className="swiper-card">
                  <div className="testimonial-image">
                    <img src={testimonial.image} alt={`testimonial ${index + 1}`} />
                  </div>
                  <div className="testimonial-text">
                    <p>
                      {readMore[index] ? testimonial.text : `${testimonial.text.substring(0, 100)}...`}
                      <button onClick={() => handleReadMoreToggle(index)}>
                        {readMore[index] ? 'Read Less' : 'Read More'}
                      </button>
                    </p>
                    <h2>{testimonial.name}</h2>
                    <h5>{testimonial.title}</h5>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </div>
  )
}

export default OurWork;
