import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import Header from "./components/Header";
import Contact from "./components/Contact";
import PrivacyPage from "../src/pages/PrivacyPage";
import RefundPolicy from "../src/pages/RefundPolicy";
import TermsandCondition from "../src/pages/TermsandCondition";
import Disclaimer from "../src/pages/Disclaimer";
import MainPage from "./pages/MainPage";
import AboutRead from "./pages/AboutRead";
import DonateNow from "./pages/DonateNow";

function App() {
  
  
  return (
    <Router>
      <div>
        <Header  />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/refund" element={<RefundPolicy />} />
          <Route path="/terms" element={<TermsandCondition />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/more" element={<AboutRead/>} />
          <Route path="/donate" element={<DonateNow />} />
        </Routes>
        <Contact />
      </div>
    </Router>
  );
}

export default App;

























