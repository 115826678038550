import React from "react";
import QR from "../assests/QR.jpg"


function DonateNow() {
  const bankDetails = {
    accountName: "Vallabhi Green Foundation",
    accountNumber: "10087453648",
    ifsc: "IDFB0020158",
    bankName: "IDFC First Bank",
  };

  return (
    <div className="DonateNow" id="DonateNow">
      <div className="innerDonatediv">
        <h1>ShriNarayan Vrihad Gaushala Donation Form</h1>
        <p className="shloka">गावो विश्वस्य मातरः</p>

        <div className="bank-details">
          <h3>Bank Details</h3>
          <p><strong>Account Name:</strong> {bankDetails.accountName}</p>
          <p><strong>Account Number:</strong> {bankDetails.accountNumber}</p>
          <p><strong>IFSC Code:</strong> {bankDetails.ifsc}</p>
          <p><strong>Bank Name:</strong> {bankDetails.bankName}</p>
        </div>
        <div className="qr-code">
          <p className="payText">Scan this QR for UPI payment</p>
          <img src={QR} alt="QR Code for Donation" />
        </div>

        <form className="donation-form">
          <label>Name:</label>
          <input type="text" placeholder="Enter your name" required />

          <label>Email:</label>
          <input type="email" placeholder="Enter your email" required />

          <label>Address:</label>
          <input type="text" placeholder="Enter your address" required />

          <label>Review:</label>
          <textarea placeholder="Enter your review" required></textarea>

          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default DonateNow;
