import React from 'react'
import image from "../assests/sir.JPG"
const AboutRead = () => {
    return (
        <div className="readmore" id="readmore">

            <div className='chairman_heading'>
                <p>Meet Our Visionary</p>
            </div>

            <div className='about'>
                <p>Mr. Narendra Shukla is a proficient leader with social, political and technical expertise.
                    He upholds high ethical values and is knowledgeable in management, economics and politics.
                    With excellent communication abilities, he comprehends various public policy issues like economics, social
                    welfare and national security, to make well-informed decisions.
                    <br />
                    <br />
                    Mr. Shukla is a seasoned professional with extensive experience in Facilities Management and Real Estate portfolio.
                    He was the Director of Lilac Facilities Management Private Limited, where he led the strategizing efforts to generate value
                    and align resources to meet business objectives while remaining compliant with the law of the land.
                    <br />
                    <br />
                    He also served as the Facility Head at Airtel India, where he demonstrated his ability to manage partners across
                    the CRE domain and oversee the delivery of services and projects such as Network Data Centre, MSCs, Offices & Campuses.
                    <br />
                    <br />
                    Mr. Shukla began his career in the Indian Navy as an Aircraft Maintenance Engineer, where he specialized in the
                    maintenance of aircraft navigational systems of Russian origin aircraft TU142M. He received his Electrical Engineering degree
                    with a specialization in Aviation from the Naval Institute of Aviation Training in 1988.</p>
                <img src={image}  alt="gaushala"/>
            </div>

            <div className='abouthead'>
                <p>About Vallabhiindia</p>
            </div>

            <div className='content'>
                <p>
                    Committed to environmental conservation and sustainable development, Vallabhi Green Foundation runs various green initiatives, including a gaushala dedicated to the welfare of cows.
                    Our gaushala is a sanctuary that provides a safe and nurturing environment for cows, emphasizing their care, health, and well-being.
                    It reflects our dedication to preserving indigenous cattle breeds and promoting sustainable agricultural practices.
                    Vallabhiindia is a dynamic umbrella company that oversees a diverse portfolio of enterprises, each excelling in its respective field.
                    Our commitment to excellence, innovation, and community welfare drives every initiative under our brand.
                </p>
            </div>

            <div className='childrenConatiner'>
                <h2>Our Companies:</h2>

                <h4>1.Vallabhi Engineering Services:</h4>
                <p>Specializing in cutting-edge engineering solutions, Vallabhi Engineering Services delivers high-quality
                    projects across various sectors, ensuring sustainability and innovation.</p>

                <h4>2.Vallabhi Agro Service:</h4>
                <p>Dedicated to enhancing agricultural productivity, Vallabhi Agro Service provides comprehensive support to farmers,
                    including modern techniques, equipment, and advisory services.</p>

                <h4>3.Vallabhi Impex:</h4>
                <p>Engaged in global trade, Vallabhi Impex handles the import and export of a wide range of products,
                    fostering international business relationships and contributing to the global market.</p>

                <h4>4.Vallabhi Spark Reality:</h4>
                <p>A leader in real estate development, Vallabhi Spark Reality creates modern, sustainable living and commercial spaces,
                    transforming landscapes and enhancing community life.</p>

                <h4>5.Vallabhi Drvya:</h4>
                <p>Focused on natural and holistic health solutions, Vallabhi Drvya offers a range of herbal products and remedies,
                    promoting wellness and a healthy lifestyle.</p>

                <h4>6.Vallabhi Green Foundation:</h4>
                <p>Committed to environmental conservation and sustainable development, Vallabhi Green Foundation runs various green initiatives,
                    including a gaushala dedicated to the welfare of cows.</p>

                <h4>7.Kleem Hospitals:</h4>
                <p>Providing top-notch healthcare services, Kleem Hospitals combines advanced medical technology with
                    compassionate care to ensure the best patient outcomes.</p>

                <h4>8.Phygital Telmeds:</h4>
                <p>Bridging the gap between physical and digital healthcare, Phygital Telmeds offers telemedicine services,
                    making healthcare accessible to everyone, everywhere.</p>

                <h4>9.Vallabhi Diagnostic:s</h4>
                <p>Equipped with state-of-the-art technology, Vallabhi Diagnostic offers accurate and timely diagnostic services,
                    crucial for effective healthcare delivery.</p>

                <h4>10.Vallabhi IT Services:</h4>
                <p>Catering to a wide range of technological needs, Vallabhi IT Services provides innovative solutions in software development, IT consulting,
                    and digital transformation..</p>
            </div>

            <div className='vision'>
                <p >
                    Our Vision
                </p>
            </div>

            <div className='visionTxt'>
                <p>At Vallabhiindia, our vision is to create a positive impact across diverse industries, fostering growth, innovation, and sustainability.
                    We strive to be a trusted name in every sector we operate in, dedicated to improving lives and building a better future.</p>
            </div>
        </div>
    )
}

export default AboutRead