import React from "react";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

const Header = ({ openLoginForm }) => {
  return (
    <div className="header">
      <div className="header-title">
        <p className="animated-text link2">
          <Link2 to="/" >
            Shrinarayan Vrihad Gaushala
          </Link2></p>
      </div>
      <div className="header-side-links">
        <ul>
          <li className="link2">
            <Link2 to="/" >
              Home
            </Link2>
          </li>

          <li>
            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={0}
              duration={1000}
            >
              About us
            </Link>
          </li>

          <li>
            <Link
              to="gallery"
              spy={true}
              smooth={true}
              offset={0}
              duration={1000}
            >
              Gallery
            </Link>
          </li>

          <li>
            <Link
              to="testimonials"
              spy={true}
              smooth={true}
              offset={0}
              duration={1000}
            >
              Our Work
            </Link>
          </li>

          <li>
            <Link
              to="ourWork"
              spy={true}
              smooth={true}
              offset={0}
              duration={1000}
            >
              Testimonial
            </Link>
          </li>
          <li>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={0}
              duration={1000}
            >
              Contact Us
            </Link>
          </li>

        </ul>

      </div>
      <div className="btn link2">
        <Link2 to="/donate">Donate Now</Link2>
      </div>
    </div>
  );
};

export default Header;
