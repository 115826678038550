import React from "react";

const PrivacyPage = () => {
  return (
    <div className="privacy" id="privacy">
      <div className="heading">
        <p>PRIVACY POLICY</p>
      </div>

      <div className="content">
        <p>
          Welcome to the official website of Shri Narayan Vrihad Gaushala. We
          are committed to protecting and respecting your privacy. This Privacy
          Policy explains how we collect, use, disclose, and safeguard your
          information when you visit our website. Please read this policy
          carefully to understand our views and practices regarding your
          personal data and how we will treat it.
        </p>
        <p>
          When you visit our Site, we may collect personal information that you
          voluntarily provide to us. This includes contact details such as your
          name, email address, and phone number. We also collect information
          related to donations, including payment details, although this payment
          information is processed through secure third-party payment
          processors. Additionally, we may gather information about your
          communication preferences, such as whether you wish to receive updates
          and news from us. Alongside personal information, we also collect
          non-personal data automatically as you interact with our Site. This
          non-personal information includes usage data, such as your IP address,
          browser type, and pages visited. We may also use cookies, which are
          small data files stored on your device to track your activity on our
          Site. You have the option to manage or disable cookies through your
          browser settings if you prefer not to have these cookies stored on
          your device.
        </p>
        <p>
          We use the information we collect to provide you with our services,
          including processing donations, responding to your inquiries, and
          managing our operations. Additionally, we use this information to
          improve our Site by understanding how users interact with it and
          enhancing the overall user experience. We may also use your
          information to send you updates about news, events, and other matters
          related to our Gaushala, though you can opt out of these communications
          at any time. Furthermore, we may use your information to comply with
          legal obligations or to protect the rights, property, or safety of
          Shri Narayan Vrihad Gaushala, our users, or others.
        </p>
        <p>
          We take appropriate technical and organizational measures to protect
          your personal information from unauthorized access, use, or disclosure.
          However, please note that no method of internet transmission or
          electronic storage is entirely secure. While we strive to employ
          commercially acceptable means to protect your personal data, we cannot
          guarantee its absolute security. We do not sell, trade, or otherwise
          transfer your personal information to external parties except in
          certain circumstances. We may share your information with third-party
          service providers who help us operate our Site, process donations, or
          conduct our business, provided that these parties agree to keep your
          information confidential. Additionally, we may disclose your
          information to comply with legal requirements or to safeguard the
          rights, property, or safety of Shri Narayan Vrihad Gaushala, our
          users, or others.
        </p>
        <p>
          You have several rights concerning your personal information. You may
          request access to or correction of your personal data held by us. You
          also have the option to opt out of receiving promotional communications
          from us by following the instructions provided in those communications.
          Furthermore, you can manage or disable cookies through your browser
          settings if you prefer not to have them stored.
        </p>
        <p>
          We may update this Privacy Policy from time to time, and any changes
          will be posted on this page with an updated effective date. We
          encourage you to review this policy periodically to stay informed about
          how we are protecting your information.
        </p>
        <p>
          If you have any questions or concerns regarding this Privacy Policy
          or our data protection practices, please contact us at Shri Narayan
          Vrihad Gaushala via email or by phone.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPage;
