import React from "react";

const TermsandCondition = () => {
  return (
    <div className="terms" id="terms">
      <div className="heading">
        <p> Terms and Condition </p>
      </div>

      <div className="content">
        <p>
          Welcome to ShriVrihad Narayan Gaushala's website. By accessing or
          using our website, you agree to comply with and be bound by the
          following terms and conditions. Please read these terms carefully
          before using our site.
        </p>

        <h3>Use of the Site:</h3>
        <p>
          By using this site, you represent that you are at least the age of
          majority in your state or province of residence. You agree not to use
          our site for any illegal or unauthorized purpose and that you will not
          violate any laws in your jurisdiction while using our site.
        </p>

        <h3>Donations:</h3>
        <p>
          All donations made to ShriVrihad Narayan Gaushala are final and
          non-refundable. If you experience any issues with your donation or if
          a payment gets stuck, please contact our helpline or email us , and we
          will assist you in resolving the issue.
        </p>

        <h3>Intellectual Property:</h3>
        <p>
          The content on this site, including text, graphics, logos, images, is
          the property of ShriVrihad Narayan Gaushala and is protected by
          applicable intellectual property laws. You may not use, reproduce,
          distribute, or create derivative works from any content on this site
          without our express written permission.
        </p>

        <h3>Limitation of Liability:</h3>
        <p>
          ShriVrihad Narayan Gaushala will not be liable for any direct,
          indirect, incidental, consequential, or punitive damages arising from
          your use of, or inability to use, the site. This includes, but is not
          limited to, damages for errors, omissions, interruptions, defects,
          delays, computer viruses, loss of profits, and loss of data.
        </p>

        <h3>Privacy:</h3>
        <p>
          Your use of our site is also governed by our Privacy Policy, which
          outlines how we collect, use, and protect your personal information.
          By using our site, you consent to the practices described in our
          Privacy Policy.
        </p>

        <h3>Changes to the Terms:</h3>
        <p>
          We reserve the right to modify these terms and conditions at any time.
          Any changes will be effective immediately upon posting on this page.
          Your continued use of the site following the posting of any changes
          constitutes your acceptance of those changes.
        </p>

        <h3>Governing Law:</h3>
        <p>
          These terms and conditions are governed by and construed in accordance
          with the laws of Uttar Pradesh, without regard to its conflict of law
          principles. Any disputes arising under or in connection with these
          terms shall be subject to the exclusive jurisdiction of the courts.
        </p>

        <h3>Contact Us:</h3>
        <p>
          If you have any questions about these Terms and Conditions, please
          contact us.
        </p>

        <p>
          By using our site, you acknowledge that you have read, understood, and
          agree to be bound by these Terms and Conditions.
        </p>
      </div>
    </div>
  );
};

export default TermsandCondition;
