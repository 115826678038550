import React from 'react';
import FrontImage from "../assests/homePage.png"

const Home = () => {
  return (
  <div className='home' id='home'>
    <img  className='image' src={FrontImage} alt='not found'/>
  </div>
    
  );
};

export default Home;

